import { of } from "rxjs";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../shared/services/apiService";
import { API_ENDPOINT, ReportCategory, GST_API } from "../../shared/types/enums";

export default class ReportStatusDetailsService {


    static getActiveTenants = () => {
        const targetUrl = `/GetActiveTenants/false`;
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            targetUrl,
        );
    }
    static fetchReportDetails(_selTenantId) {
        /**
         * As per the discussion with the DB Team
         *  
         */
        // const jsonObj = {
        // "tenantId": _selTenantId,
          
        // };
        // TODO: ensure that this  is fetching from ReportMaster Table for the selected tenant
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportTaskDetailsView/${_selTenantId}`);
    }

    // column-definitions
    static getReportColumnDefs = (_parentThisRef) => {
        
        let ReportArr = [
           
            AgGridColumnExt.GET(false, "text", "taskId", "Task Id").flexWeight(1).headerClass("center-text").leftAlignText().SET(),// AgGridColumnExt.GET(false, "text", "azureDataFactoryName", "Azure Data Factory Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            //AgGridColumnExt.GET(false, "text", "report_Metadata", "ReportMetadata").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "reportName", "ReportName").flexWeight(1).headerClass("center-text").leftAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "reportStatus", "Status").fixedWidth(100).flexWeight(1).cellRenderer("FileStatusImageRenderer").tooltip().headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "date_started", "StartedOn").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "date_Completed", "CompletedOn").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "createdUser", "Created User").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "createdDate", "CreatedOn").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "schedule_flag", "IsScheduled").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "schedule_timestamp", "ScheduledFor").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            
            //AgGridColumnExt.GET(false, "text", "statusReason", "Status Reason").flexWeight(1).headerClass("center-text").tooltip().centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "azureDataFactoryName", "Azure DataFactoryName").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "pipelineName", "PipelineName").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
           
        ];
        return ReportArr;
      }

    }
